import { toggleConsents } from "@ludens-reklame/rubics-v3-sdk/consent";
import { InputConsent } from "@ludens-reklame/rubics-v3-sdk/consent/types";

export async function handleConsents(
  input: Record<string, any>,
  reference?: string
): Promise<void> {
  const consents: InputConsent[] = [];

  for (const key in input) {
    if (key.startsWith("consent:")) {
      const consent = key.replace("consent:", "");
      const value = input[key];

      consents.push({
        reference,
        consent,
        accepted: value === "on",
      });
    }
  }

  if (consents.length === 0) {
    return;
  }

  await toggleConsents(...consents);
}
