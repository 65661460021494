import { RichText } from "../richText/RichText.js";

export interface SettableConsent {
  consent?: string;
  text?: string;
  required?: boolean;
}

interface Props {
  consents: SettableConsent[];
}

export const Consents: React.FC<Props> = ({ consents }) => {
  if (consents.length === 0) {
    return null;
  }

  return (
    <div className="consents">
      {consents.map((consent) => {
        if (!consent.consent || !consent.text) return null;

        return (
          <label key={consent.consent} className="b1">
            <input
              type="checkbox"
              name={`consent:${consent.consent}`}
              required={consent.required}
            />
            <RichText html={consent.text} />
          </label>
        );
      })}
    </div>
  );
};
